import { BASE_URL } from '@utils/constants';

export const getStartUnix = d => {
  const start = d;
  start.setHours(0, 0, 0, 0);
  const startUnix = Math.round(start / 1000);
  return startUnix;
};

export const getEndUnix = d => {
  const end = d;
  end.setHours(23, 59, 59, 999);
  const endUnix = Math.round(end / 1000);
  return endUnix;
};

export const pad = no => `0${no}`.slice(-2);

export const formatDateTime = d => {
  const date = new Date(d);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${pad(day)}/${pad(month)}/${year} ${pad(hour)}:${pad(minute)}`;
};
export const formatShortDateTime = d => {
  const date = new Date(d);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${pad(day)}/${pad(month)} ${pad(hour)}:${pad(minute)}`;
};

export const formatDate = (d, defaultValue = '-') => {
  if (!d) return defaultValue;
  const date = new Date(d);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${pad(day)}/${pad(month)}/${year}`;
};

export const formatTime = d => {
  const date = new Date(d);
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${pad(hour)}:${pad(minute)}`;
};
export const formatNumber = (v = '', defaultValue = '-') => {
  if (!v) return defaultValue;
  return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
};

export const formatMoneyNumber = (v = '') => {
  const number = parseInt(v);
  if (number > 1000000) {
    let mainNum = Math.floor(number / 1000000);
    const subNum = number % 1000000;
    let result = `${formatNumber(mainNum)}`;
    if (subNum >= 100000) {
      result = `${result}.${Math.round(subNum / 100000)}`;
    }
    return `${result}T`;
  } else if (number > 1000) {
    return `${formatNumber(Math.floor(number / 1000))}k`;
  }
  return v;
};

export const duration = dur => {
  let durTime = dur || 0;
  const hour = Math.floor(durTime / 3600);
  durTime -= hour * 3600;
  const minute = Math.floor(durTime / 60);
  // durTime -= minute * 60;
  // const second = durTime;
  const hourMes = hour ? `${hour} tiếng ` : '';
  const minuteMes = `${minute || 1} phút `;
  // const secondMes = second ? `${second} giây ` : '';
  return `${hourMes}${minuteMes}`;
};

export const awaitWrapper = fn => fn.then(res => [false, res]).catch(err => [err]);

export const getLocation = (navigator) => {
  return new Promise(resolve => {
    if (!navigator.geolocation) resolve({ latitude: 0, longitude: 0 });
    else {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        resolve({ latitude, longitude })
      }, function (error) {
        resolve({ latitude: 0, longitude: 0 });
      });
    }
  })
}

export const getImageUrl = image => {
  if (!image) {
    return '';
  }
  if (image.indexOf('http') > -1) {
    return image;
  }
  return `${BASE_URL}${image}`;
}

export const validateFields = form =>
  new Promise(resolve => {
    form.validateFields((err, value) => resolve([err, value]));
  });

export const parseJson = (str, defaultResult = '') => {
  let result = defaultResult;
  try {
    result = JSON.parse(str);
  } catch {
    result = defaultResult;
  }
  return result || defaultResult;
}

export const searchText = (sourceString = '', findString = '') => {
  const sourceText = removeAccent(`${sourceString}`.toLowerCase());
  const findText = removeAccent(`${findString}`.toLowerCase());
  return sourceText.includes(findText);
}

export const removeAccent = str => {
  let result = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  result = result.toLowerCase();
  result = result.replace(/đ/g, 'd');
  return result;
}

export const groupBy = (xs, key, defaultValue = 'Unknown') => {
  return xs.reduce((rv, x) => {
    const val = x[key] || defaultValue;
    if (!rv.includes(val)) {
      rv.push(val);
    }
    return rv;
  }, []);
};

// Converts numeric degrees to radians
const toRad = (Value) => {
  return Value * Math.PI / 180;
}
export const calcCrow = (latitude1, longitude1, latitude2, longitude2) => {
  var R = 6371; // km
  var dLat = toRad(latitude2 - latitude1);
  var dLon = toRad(longitude2 - longitude1);
  var lat1 = toRad(latitude1);
  var lat2 = toRad(latitude2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000; //meter
}

var IDX = 36, HEX = '';
while (IDX--) HEX += IDX.toString(36);

export const uid = (len = 11) => {
  var str = '', num = len;
  while (num--) str += HEX[Math.random() * 36 | 0];
  return str;
}