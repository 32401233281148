import React from "react";
import { useState, useEffect } from "react";
import { ClockLoader } from "react-spinners";
import request from '@utils/request';
import { awaitWrapper } from '@utils/helper';

export default function ImageList({ data, loading, onSelect, onDelete, allowDelete }) {
  const [isLoading, setIsLoading] = useState(false);
  let lastDate = '';
  const handleSelect = e => {
    e.preventDefault();
    const id = parseInt(e?.currentTarget?.dataset?.id || '0', 0);
    if (id) {
      onSelect(id);
    }
  }
  const handleDelete = async e => {
    e.preventDefault();
    setIsLoading(true);
    const id = parseInt(e?.currentTarget?.dataset?.id || '0', 0);
    if (id) {
      const [err] = await awaitWrapper(request.delete(`v1/gallery/${id}`));
      if (!err) {
        onDelete(id);
      }
    }
    setIsLoading(false);
  }

  const getDate = (createdAt = 0) => {
    const date = new Date(createdAt * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${day}-${month}-${year}`;
  }

  const renderDateTitle = (item) => {
    const date = getDate(item.createdAt);
    let dateTitle = '';
    if (date !== lastDate) {
      lastDate = date;
      dateTitle = <span className="image text">{lastDate}</span>;
    }
    return dateTitle;
  }

  const initData = () => {
    const result = [];
    data.forEach(item => {
      const date = getDate(item.createdAt);
      let ind = result.findIndex(x => x.date === date);
      if (ind < 0) {
        result.push({
          date,
          list: []
        })
        ind = result.length - 1;
      }
      result[ind].list.push(item);
    });
    return result;
  }

  return (
    <div className="imageList">
      {loading ? (<div className="loader">
        <ClockLoader />
      </div>) :
        (
          initData().map(x => (
            <>
              <p>{x.date}</p>
              <ul className="image-gallery">
                {x.list.map((item) => (
                  <li key={item.id} >
                    <img src={item.src} data-id={item.id} onClick={handleSelect} />
                    {allowDelete && <a className="btnDelete" data-id={item.id} onClick={handleDelete}>X</a>}
                    {isLoading && (<div className="loader">
                      <ClockLoader />
                    </div>)}
                  </li>
                ))}

              </ul>
            </>

          ))
        )}
    </div>
  );
}


ImageList.defaultProps = {
  data: [],
  onSelect: () => { },
  onDelete: () => { },
  allowDelete: false,
  loading: false,
}