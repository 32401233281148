export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PLACE = {
  OFFICE: 2,
  SHOP48: 1,
  SHOP222: 3,
  SHOP257: 4,
}

export const CHECKIN_PLACE = {
  OFFICE: 1,
  SHOP48: 3,
  SHOP222: 4,
  SHOP257: 5,
}

export const PLACE_ENUM = {
  [PLACE.OFFICE]: 'Kho',
  [PLACE.SHOP48]: 'HVH',
  [PLACE.SHOP222]: 'NDC',
  [PLACE.SHOP257]: 'NH'
}

export const PLACE_LIST = [
  { value: PLACE.OFFICE, label: PLACE_ENUM[PLACE.OFFICE] },
  { value: PLACE.SHOP48, label: PLACE_ENUM[PLACE.SHOP48] },
  { value: PLACE.SHOP222, label: PLACE_ENUM[PLACE.SHOP222] },
  { value: PLACE.SHOP257, label: PLACE_ENUM[PLACE.SHOP257] },
]

export const BASE_URL = process && process.env ? process.env.REACT_APP_BASE_URL : 'https://api.tina-princess.n4u.vn/';

export const SIZE_COLUMN = ['sizeM', 'sizeL', 'sizeXL', 'sizeXXL'];
