import React from "react";
import { useState, useEffect } from "react";
import { useMutation } from 'react-query';
import ImageUploader from 'react-images-upload';

import request from '@utils/request';


import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/dist/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

import UploadImage from "./UploadImage";
import ImageList from "./ImageList";
import './index.less';


export default function App() {
  const [index, setIndex] = useState(-1);
  const [isUpload, setIsUpload] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [data, setData] = useState([]);
  const [showUpload, setShowUpload] = useState(false);

  const { isLoading, mutate: loadData } = useMutation(() => {
    return request.get(`gallery`);
  },
    {
      onSuccess: (response) => {
        const data = response.data || [];
        const imageSource = data.map(x => ({ id: x.id, src: `https://api.tina-princess.n4u.vn/public/${x.imageUrl}`, createdAt: x.imageCreatedAt }))
        setData(imageSource);
      },
    })

  useEffect(() => {
    loadData();
    const token = localStorage.getItem('token') || '';
    if (token.length) setShowUpload(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDrop = (pictureFiles, pictureDataURLs) => {
    const pics = pictures.filter(x => !x.isComplete);
    //lastModified, name, size
    pictureFiles.forEach((pic, index) => {
      const name = pic.name;
      const ind = pics.findIndex(x => x.file.name === name);
      if (ind < 0) {
        pics.push({
          file: pic,
          dataUrl: pictureDataURLs[index] || ''
        })
      }
    });
    setPictures(pics);
  }
  const handleDelete = (index) => {
    const newPictures = [
      ...pictures
    ]
    newPictures.splice(index, 1);
    setPictures(newPictures);
  }
  const handleUploadComplete = index => {
    const newPictures = [
      ...pictures
    ]
    newPictures[index].isComplete = true;
    setPictures(newPictures);
  }
  const handleSelect = id => {
    const ind = data.findIndex(x => x.id === id);
    setIndex(ind);
  }
  const handleDeleteImage = id => {
    const ind = data.findIndex(x => x.id === id);
    const newData = [
      ...data
    ]
    newData.splice(ind, 1);
    setData(newData);
  }

  return (
    <>
      {showUpload && <div className="imageContainer">
        <button disabled={!pictures.length} onClick={() => setIsUpload(true)}>Upload</button>
        <ImageUploader
          withIcon={true}
          buttonText='Choose images'
          onChange={onDrop}
          imgExtension={['.jpg', '.jpeg', '.png']}
          maxFileSize={5242880}
        />
        {pictures.map((pic, index) => (<UploadImage key={index} isUpload={isUpload} item={pic} index={index} onDelete={handleDelete} onUploadSuccess={handleUploadComplete} />))}
      </div>}

      <ImageList allowDelete={showUpload} data={data} onSelect={handleSelect} onDelete={handleDeleteImage} loading={isLoading} />

      <Lightbox
        slides={data}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
}
