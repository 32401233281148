import axios from 'axios';
// import { message } from 'antd';
import { BASE_URL } from '@utils/constants';

const instance = axios.create();
instance.defaults.baseURL = BASE_URL;

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.token = `${token}`; // eslint-disable-line no-param-reassign
    }
    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && window.location.pathname.indexOf('signin') < 0) {
      // message.error(`Not allowed`);
      console.log('Not allowed');
    } else {

      console.log(`(${error.response.status}) ${error.response.data.message || error.response.data.error}`);
    }
    return Promise.reject(error);
  },
);

export default instance;
