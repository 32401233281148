import React from "react";
import { useState, useEffect } from "react";
import { ClockLoader } from "react-spinners";

import request from '@utils/request';

export default function UploadImage({ item, index, isUpload, onUploadSuccess, onUploadError }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isUpload) {
      uploadImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpload])

  const uploadImage = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('files', item.file);
    formData.append('fileName', item.file.name);
    const { lastModified } = item.file;
    let imageCreatedAt = 0;
    if (lastModified) {
      imageCreatedAt = Math.round(lastModified / 1000);
    }
    formData.append('imageCreatedAt', imageCreatedAt);
    return request.post('v1/gallery', formData)
      .then(res => {
        setLoading(false);
        onUploadSuccess(index, res.data);
        console.log(res)
      })
      .catch(err => {
        setLoading(false);
        onUploadError(index, err);
        console.log(err)
      });
  }

  const handleDelete = e => {
    e.preventDefault();
    const index = e?.target?.dataset?.index || -1;
    if (index > -1) {
      this.props.onDelete(index);
    }
  }

  return (
    <div className="item" key={index}>
      {loading && (<div className="loader">
        <ClockLoader />
      </div>)}
      {item.isComplete ? <span className="complete">✓</span> :
        <a className="btnDelete" data-index={index} onClick={handleDelete}>X</a>}
      <div className="image" style={{ backgroundImage: `url(${item.dataUrl})` }} />
    </div>
  );
}


UploadImage.defaultProps = {
  item: {},
  index: -1,
  isUpload: false,
  onUploadSuccess: () => { },
  onUploadError: () => { },
  onDelete: () => { },
}