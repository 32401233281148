import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import App from './App';
import Header from './Header';

const boot = () => {
  const MOUNT_NODE = document.getElementById('root');
  const queryClient = new QueryClient();
  const render = () => {
    ReactDOM.render(
      <>
        <Header />
        <main>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </main>
      </>,
      MOUNT_NODE,
    );
  };

  if (module.hot) {
    module.hot.accept(['./App'], () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    });
  }
  render();
};

boot();
