import React from "react";
import { useState } from "react";

export default function Header() {
  const [token, setToken] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleChange = e => {
    setToken(e.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      localStorage.setItem('token', token);
      window.location.reload();
    }
  };

  return (
    <header>
      <h1 onClick={() => setShowInput(true)}>My Little Princess</h1>
      {showInput && <input onenter value={token} onChange={handleChange} onKeyDown={handleKeyDown} />}
    </header>
  );
}


Header.defaultProps = {
  data: [],
  onSelect: () => { }
}